.ie8 {
  body.foundation-finder-page .is-sticky.page-header {
    background: #fae7da;
    position: absolute !important;
  }
  .page-utilities__cart {
    width: 55px;
    .page-utilities__cart-button {
      padding-left: 14px;
    }
    .page-utilities__cart-count {
      padding-left: 0;
    }
  }
  .sign-in-component {
    input[type='checkbox'] {
      padding-top: 5px;
    }
  }
  #shipping_billing {
    #cboxWrapper {
      #cboxContent {
        float: none !important;
      }
    }
    .address-form__title_container {
      INPUT[type='radio'] {
        float: none;
        vertical-align: middle;
      }
    }
  }
  #shipping {
    #cboxWrapper {
      #cboxContent {
        float: none !important;
        padding-right: 20px;
        padding-left: 20px;
        #cboxClose {
          right: 40px;
        }
      }
    }
    .address-form__title_container {
      width: 100%;
    }
    .address-form .form-item INPUT[type='radio'].field {
      float: none !important;
      width: auto !important;
      vertical-align: middle;
    }
  }
  #cboxContent #cboxClose {
    right: 25px;
    height: 28px;
    width: 28px;
  }
}

.colorbox__us-ie-upgrade {
  #cboxContent,
  #cboxLoadedContent {
    height: auto !important;
  }
}

body.ie {
  #rn_container {
    #renutriv_section-1 .content p,
    div.side_nav li a,
    #renutriv_section-2 .content p.first,
    #renutriv_section-4 li p {
      font-family: $roboto-condensed !important;
    }
  }
  #pm_container #pm_section-4 {
    #pm_section_content-4_3 {
      div.interchange {
        div.right,
        div.left {
          background: none;
        }
      }
    }
  }
}

.ie-9 {
  select {
    background: none;
    padding: 0;
  }
}
