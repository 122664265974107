div.csr_phone_number {
  display: none;
}

.page-wrapper,
.wrapper {
  font-size: 15px;
  .brand-id-Lauder {
    h3 {
      font-size: 50px;
      text-decoration: underline;
      margin: 0;
    }
    h4 {
      font-size: 30px;
      font-weight: normal;
    }
  }
}
